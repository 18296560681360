



































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import i18n from '@improve/common-utils/src/i18n';
import BaseButton from './BaseButton.vue';

@Component({
  name: 'BaseButtonActions',
  components: {
    BaseButton
  }
})
export default class BaseButtonActions extends Vue {
  @Prop({ default: false }) readonly inProgress!: boolean;

  @Prop({ default: false }) readonly save!: boolean;

  @Prop({ default: false }) readonly plainDeleteStyle!: boolean;

  @Prop({ default: () => i18n.t('form.button.save') }) readonly saveTitle!: string;

  @Prop({ default: false }) readonly cancel!: boolean;

  @Prop({ default: () => i18n.t('form.button.cancel') }) readonly cancelTitle!: string;

  @Prop({ default: false }) readonly remove!: boolean;

  @Prop({ default: () => i18n.t('form.button.remove') }) readonly removeTitle!: string;
}

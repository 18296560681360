import { render, staticRenderFns } from "./PasswordInput.vue?vue&type=template&id=454a036c&scoped=true&"
import script from "./PasswordInput.vue?vue&type=script&lang=ts&"
export * from "./PasswordInput.vue?vue&type=script&lang=ts&"
import style0 from "./PasswordInput.vue?vue&type=style&index=0&id=454a036c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "454a036c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCardText})

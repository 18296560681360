













































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import User from '@improve/common-utils/src/model/User';
import FileUploadRequest from '@improve/common-utils/src/types/FileUploadRequest';
import dayjs from 'dayjs';
import { localize } from 'vee-validate';
import BaseButtonActions from '../widgets/BaseButtonActions.vue';
import BaseButton from '../widgets/BaseButton.vue';
import DeletionConfirmation from '../widgets/DeletionConfirmation.vue';
import BaseProfile from './BaseProfile.vue';

@Component({
  name: 'PageProfile',
  components: {
    BaseButtonActions,
    BaseProfile,
    BaseButton,
    DeletionConfirmation
  }
})
export default class PageProfile extends Vue {
  @Prop({ default: false }) readonly initialInput!: boolean;

  @Prop({ default: false }) readonly notificationsSettings!: boolean;

  @Getter currentUser?: User;

  @Action updateCurrentUser!: ActionMethod;

  @Action deleteUser!: ActionMethod;

  @Action logout!: ActionMethod;

  @Action uploadUserAvatarBase64!: ActionMethod;

  @Action removeUserAvatar!: ActionMethod;

  @Action setPassword!: ActionMethod;

  newPassword: string | undefined = undefined;

  askDeletionConfirmation = false;

  isDeleting = false;

  get pageHeader(): string {
    return (this.initialInput
      ? this.$t('page.editProfile.completeProfileHeader')
      : this.$t('page.editProfile.editProfileHeader'))
      .toString();
  }

  onAvatarSelect(avatar: FileUploadRequest): void {
    this.uploadUserAvatarBase64(avatar.dataUrl);
  }

  onAvatarRemove(): void {
    this.removeUserAvatar();
  }

  async validateAndSave(): Promise<void> {
    const profileEditor: any = this.$refs.baseProfile;
    const valid = await profileEditor.validate();
    if (valid) {
      const updatedUser = profileEditor.prepareUser();
      await this.updateCurrentUser(updatedUser);
      if (this.newPassword && !!this.newPassword.length) {
        await this.setPassword({ password: this.newPassword });
      }
      if (updatedUser.language !== this.$i18n.locale) {
        this.$i18n.locale = updatedUser.language.toLowerCase();
        localize(this.$i18n.locale);
        dayjs.locale(this.$i18n.locale);
      }
      this.goBack();
    }
  }

  async deleteProfile(): Promise<void> {
    this.isDeleting = true;
    await this.deleteUser(this.currentUser?.id);
    await this.logout();
  }

  goBack(): void {
    if (this.initialInput) {
      // if user just finished setting up his profile
      this.$router.push({ name: 'Dashboard' });
    } else {
      this.$router.push({ name: 'AboutTab' });
    }
  }
}















import { Component } from 'vue-property-decorator';
import BaseFormControl from '../../helpers/BaseFormControl';

@Component({
  inheritAttrs: false,
  name: 'BaseFileUpload'
})
export default class BaseFileUpload extends BaseFormControl {
  file: File | undefined;

  fileDataUrl: string | null = null;

  static readDataUrl(file: File, cb: (e: string | null) => void): void {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e?.target?.result) {
        cb(e?.target?.result as string);
      } else {
        cb(null);
      }
    };
    reader.readAsDataURL(file);
  }

  selectFile(): void {
    const inputEl: any = this.$refs.singleFileUpload;
    inputEl.click();
  }

  fileChange({ target: { files } }: { target: { files: File[] } }): void {
    if (!files || !files.length) {
      throw Error('No files detected');
    }
    BaseFileUpload.readDataUrl(files[0],
      (dataUrl: string | null) => {
        this.fileDataUrl = dataUrl;
        this.$emit('onFileChange', {
          file: files[0],
          dataUrl: this.fileDataUrl
        });
      });
  }
}

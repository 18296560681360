import { render, staticRenderFns } from "./PageProfile.vue?vue&type=template&id=2644e528&scoped=true&"
import script from "./PageProfile.vue?vue&type=script&lang=ts&"
export * from "./PageProfile.vue?vue&type=script&lang=ts&"
import style0 from "./PageProfile.vue?vue&type=style&index=0&id=2644e528&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2644e528",
  null
  
)

export default component.exports
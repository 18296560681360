



















































































































































































import { extend } from 'vee-validate';
import { Component, Prop } from 'vue-property-decorator';
import { classToClass } from 'class-transformer';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import Vue from 'vue';
import Workflow from '@improve/common-utils/src/model/Workflow';
import User from '@improve/common-utils/src/model/User';
import Team from '@improve/common-utils/src/model/Team';
import Organization from '@improve/common-utils/src/model/Organization';
import NotificationType from '@improve/common-utils/src/model/NotificationType';
import FileUploadRequest from '@improve/common-utils/src/types/FileUploadRequest';
import EditProfileSection from '@improve/common-utils/src/types/EditProfileSection';
import BaseButtonActions from '../widgets/BaseButtonActions.vue';
import BaseUserAvatar from '../widgets/BaseUserAvatar.vue';
import PasswordInput from '../forms/PasswordInput.vue';
import BaseTextInput from '../core/BaseTextInput.vue';
import BaseFileUpload from '../core/BaseFileUpload.vue';
import BaseTextArea from '../core/BaseTextArea.vue';
import BaseSelect from '../core/BaseSelect.vue';
import BaseAutoComplete from '../core/BaseAutoComplete.vue';
import BaseCheckbox from '../core/BaseCheckbox.vue';

@Component({
  name: 'BaseProfile',
  components: {
    BaseButtonActions,
    BaseTextInput,
    BaseFileUpload,
    BaseUserAvatar,
    BaseSelect,
    BaseTextArea,
    BaseAutoComplete,
    PasswordInput,
    BaseCheckbox
  }
})
export default class BaseProfile extends Vue {
  @Action fetchWorkflow!: ActionMethod;

  @Getter currentOrganization?: Organization;

  @Getter organizationalUnits!: Array<Team>;

  @Getter activeWorkflow!: Workflow;

  @Prop({ default: null }) readonly user!: User;

  @Prop({ default: false }) readonly canSetPassword!: boolean;

  @Prop({ default: false }) readonly showNotificationsSettings!: boolean;

  EditProfileSection = EditProfileSection;

  avatarDataUrl: string | null = null;

  userModel: User | null = null;

  selectedSection: EditProfileSection = EditProfileSection.PROFILE;

  singleAndGroupedKeys: Array<string> = [];

  created(): void {
    this.userModel = classToClass(this.user);
    this.avatarDataUrl = this.userModel.avatar || null;
    extend('checkLinkedInUrl', {
      message: () => this.$t('error.invalidLinkedInUrl').toString(),
      validate: (e: string) => this.validateLinkedInUrl(e)
    });
    this.notificationSettingsSetup();
  }

  async notificationSettingsSetup(): Promise<void> {
    // need this if user reloads edit profile page to fetch activeWorkflow.notifications
    await this.fetchWorkflow();

    this.activeWorkflow.notifications.forEach((notification, index, self) => {
      if (!notification.group) {
        // If the notification is not part of a group
        this.singleAndGroupedKeys.push(notification.key);
      } else if (self.findIndex((n) => n.group === notification.group) === index) {
        // If the notification is the first occurence of it's group
        this.singleAndGroupedKeys.push(notification.group);
      }
    });
  }

  getNotificationsByKey(key: string): Array<NotificationType> {
    return this.activeWorkflow.notifications.filter(
      (notification) => notification.key === key || notification.group === key
    );
  }

  isNotificationEnabled(key: string): boolean {
    return this.getNotificationsByKey(key).every((notification) => {
      const setting = this.userModel?.preferences.notificationSettings?.[notification.key];

      // WORKAROUND: Notification settings are always enabled by default
      // In the future we want to take the notifyByDefault property into account
      return setting === undefined || setting;
    });
  }

  updateNotificationPreferences(key: string, value: boolean): void {
    this.getNotificationsByKey(key).forEach((notification) => {
        this.userModel!.preferences!.notificationSettings![notification.key] = value;
    });

    // this is needed since userModel.preferences.notificationSettings fields aren't reactive
    this.$forceUpdate();
  }

  validateLinkedInUrl = (url: string): boolean => url.startsWith('https://www.linkedin.com/');

  onAvatarSelect(payload: FileUploadRequest): void {
    this.avatarDataUrl = payload.dataUrl || null;
    this.$emit('onAvatarSelect', payload);
    this.$forceUpdate();
  }

  onAvatarRemove(): void {
    this.avatarDataUrl = null;
    this.$emit('onAvatarRemove');
    this.$forceUpdate();
  }

  validate(): Promise<any> {
    const component: any = this.$refs.obs;
    return component.validate();
  }

  prepareUser(): User | null {
    if (this.userModel) {
      this.userModel.avatar = this.avatarDataUrl;
    }
    return this.userModel;
  }

  navigate(target: EditProfileSection): void {
    this.selectedSection = target;
    const scrollTo: string = target === EditProfileSection.PROFILE ? 'app' : target;
    document.getElementById(scrollTo)?.scrollIntoView({
      behavior: 'smooth'
    });
  }
}
















































import Vue from 'vue';
import { extend, ValidationProvider } from 'vee-validate';
import { Component, Prop } from 'vue-property-decorator';
import { required } from 'vee-validate/dist/rules';
import BasePasswordInput from '../core/BasePasswordInput.vue';

@Component({
  inheritAttrs: false,
  name: 'PasswordInput',
  components: {
    ValidationProvider,
    BasePasswordInput
  }
})
export default class PasswordInput extends Vue {
  @Prop({ default: true }) readonly force!: boolean;

  @Prop({ default: null }) readonly newPasswordText?: string;

  @Prop({ default: null }) readonly repeatPasswordText?: string;

  password: string | null = null;

  passwordRepeat: string | null = null;

  created(): void {
    this.initialize();
  }

  initialize(): void {
    // Validate password strength
    extend('customRequired', {
      ...required,
      message: (field: string) => (field === 'password'
        ? this.$t('error.enterPassword')
        : this.$t('error.repeatPassword')).toString()
    });

    extend('checkPasswordStrength', {
      message: () => this.$t('error.weakPassword').toString(),
      validate: (e: string) => this.validatePassword(e)
    });

    // Validate password confirmation
    extend('matchPasswordInput', {
      message: () => this.$t('error.passwordsDontMatch').toString(),
      validate: (e: string) => !this.password?.length || e === this.password
    });
  }

  validatePassword = (password: string): boolean => (
    new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{9,})').test(password)
  );

  validate(): Promise<boolean> {
    const comp: any = this.$refs.passwordEntry;
    return comp.validate();
  }
}
